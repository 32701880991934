import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:title"} content={"EduQuest"} />
			<meta property={"og:description"} content={"Ваш будиночок в горах із чанами для справжнього релаксу"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Section padding="16px 40px 16px 40px" quarkly-title="Header" position="absolute" sm-padding="16px 20px 16px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				display="flex"
				justify-content="space-between"
				width="100%"
				max-width="none"
			/>
			<Box
				empty-border-color="LightGray"
				width="30%"
				align-items="center"
				lg-width="50%"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				sm-width="70%"
				empty-min-width="64px"
				empty-border-width="1px"
			>
				<Text margin="0px 0px 0px 0px" font="--headline2Lg">
					EduQuest
				</Text>
			</Box>
			<Box
				sm-width="30%"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-style="solid"
				display="flex"
				align-items="center"
				empty-border-width="1px"
				empty-border-color="LightGray"
				width="70%"
				lg-width="50%"
			>
				<Components.QuarklycommunityKitMobileSidePanel>
					<Override slot="Content" justify-content="flex-end" padding="0px 0px 0px 0px" background="none" />
					<Override
						slot="Button Text"
						md-text-transform="uppercase"
						md-color="--darkL2"
						md-letter-spacing="1px"
						md-font="--base"
					/>
					<Override slot="Button Icon" md-color="--darkL2" />
					<Box
						md-padding="20px 0px 20px 0px"
						display="flex"
						justify-content="flex-end"
						align-items="center"
						md-flex-direction="column"
					>
						<Link text-decoration-line="initial" color="--darkL2" font="--base" href="/index">
							Головна
						</Link>
						<Link
							md-margin="20px 0px 0px 0px"
							href="/pro-nas"
							text-decoration-line="initial"
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
						>
							Про нас
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/poslugi"
							text-decoration-line="initial"
						>
							Послуги
						</Link>
						<Link
							color="--darkL2"
							font="--base"
							margin="0px 0px 0px 20px"
							md-margin="20px 0px 0px 0px"
							href="/kontakti"
							text-decoration-line="initial"
						>
							Контакти
						</Link>
					</Box>
				</Components.QuarklycommunityKitMobileSidePanel>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" quarkly-title="HeroBlock" background="linear-gradient(0deg,rgba(249, 248, 243, 0.2) 0%,rgba(249, 248, 243, 0.6) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/262732078-1024x768.jpg?v=2024-11-26T14:27:26.265Z) center/cover repeat scroll padding-box" sm-padding="100px 20px 100px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				sm-min-width="280px"
			/>
			<Text
				margin="0px 0px 0px 0"
				color="--darkL2"
				font="--headline1Lg"
				width="60%"
				lg-font="--headline1Lg"
				sm-font="--headline2Lg"
				sm-width="100%"
			>
				Ваш будиночок в горах із чанами для справжнього релаксу
			</Text>
			<Text
				sm-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0"
				color="--darkL2"
				sm-text-align="left"
				font="--headline2Lg"
				width="40%"
				text-align="right"
				lg-font="--headline3Lg"
				sm-width="100%"
			>
				Мрієте втекти від міської метушні та поринути в обійми природи?
			</Text>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-flex-direction="column"
				sm-width="100%"
				md-width="100%"
				lg-width="100%"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				font="--headline2"
				width="20%"
				text-align="right"
				lg-font="--headline2Lg"
				lg-width="100%"
				lg-text-align="left"
				sm-margin="16px 0px 0px 0px"
			>
				СПОКІЙ
			</Text>
			<Image
				src="https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14:27:26.307Z"
				display="block"
				width="60%"
				sm-width="100%"
				sm-order="-1"
				srcSet="https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/132442948-446714003022045-2936834361082907267.jpg?v=2024-11-26T14%3A27%3A26.307Z&quality=85&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
			<Box width="20%" md-width="100%" lg-width="35%">
				<Text
					color="--darkL2"
					margin="0px 0px 12px 0px"
					font="--lead"
					lg-width="100%"
					sm-width="100%"
					md-width="540px"
				>
					Наш затишний будиночок у горах пропонує вам можливість відновити сили, насолодитися гармонією і отримати незабутні враження.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				flex-wrap="wrap"
				sm-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box
				width="100%"
				display="flex"
				grid-gap="20px"
				flex-direction="row"
				align-items="flex-end"
				flex-wrap="wrap"
				sm-flex-direction="column"
				sm-align-items="flex-start"
			>
				<Image
					src="https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14:27:26.484Z"
					display="block"
					width="60%"
					sm-width="100%"
					sm-order="-1"
					srcSet="https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/172546574-196109708765027-8084576242347719839.jpg?v=2024-11-26T14%3A27%3A26.484Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
				<Box
					width="30%"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					height="100%"
					justify-content="space-between"
					lg-order="-1"
					sm-width="100%"
					sm-height="auto"
				>
					<Text
						color="--darkL2"
						margin="-100px 0px 50px 100px"
						font="--headline2"
						width="100%"
						text-align="left"
						position="relative"
						lg-margin="-100px 0px 50px 0px"
						lg-font="--headline2Lg"
						sm-margin="36px 0px 0px 0px"
					>
						ТИША
					</Text>
					<Image
						src="https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14:27:26.283Z"
						display="block"
						width="100%"
						sm-order="-1"
						srcSet="https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/442031944.jpeg?v=2024-11-26T14%3A27%3A26.283Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				display="flex"
				justify-content="flex-end"
				lg-justify-content="flex-start"
				lg-width="100%"
			>
				<Text
					color="--darkL2"
					margin="20px 0px 0px 0px"
					font="--lead"
					max-width="360px"
					lg-max-width="540px"
					sm-margin="0px 0px 0px 0px"
				>
					Розташований у мальовничому куточку, наш будиночок поєднує комфорт сучасного сервісу з унікальною атмосферою карпатської природи. Аромат хвойних лісів, спів птахів і можливість попаритися в чанах на тлі гір – усе це створює ідеальні умови для відпочинку.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				width="100%"
				max-width="none"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="60%"
				grid-gap="20px"
				display="flex"
				flex-direction="column"
				align-items="flex-end"
				sm-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14:27:26.290Z"
					display="block"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745ac8e1b94b1002432debe/images/miko.webp?v=2024-11-26T14%3A27%3A26.290Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
				/>
			</Box>
			<Box
				width="40%"
				lg-width="100%"
				lg-display="flex"
				lg-flex-direction="column"
				lg-align-items="flex-end"
			>
				<Text
					color="--darkL2"
					margin="-100px 0px 50px 100px"
					font="--headline2"
					text-align="left"
					lg-width="100%"
					lg-margin="0px 0px 20px 0px"
					lg-font="--headline2Lg"
					sm-margin="16px 0px 20px 0px"
				>
					ВІДНОВЛЕННЯ
				</Text>
				<Text
					color="--darkL2"
					margin="0px 0px 0px 0px"
					font="--lead"
					width="80%"
					lg-max-width="540px"
					lg-width="100%"
				>
					🌿{" "}
					<Strong>
						Що пропонує "EduQuest24":
					</Strong>
					<br />
					<br />
					Проживання в затишному дерев’яному будинку з видом на гори.
					<br />
					<br />
					Чани з гарячою водою, трав’яними відварами та незабутньою атмосферою.
					<br />
					<br />
					Можливість організувати активний відпочинок: похід у гори, велосипедні прогулянки чи катання на лижах взимку.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 40px 100px 40px" background="--color-light" sm-padding="40px 20px 40px 20px">
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="20px"
				max-width="none"
				width="100%"
				lg-flex-direction="column"
				lg-align-items="flex-end"
				sm-min-width="280px"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--headline2"
				width="20%"
				text-align="right"
				lg-width="100%"
				lg-text-align="left"
				lg-font="--headline2Lg"
				sm-margin="16px 0px 0px 0px"
			>
				МІСЦЕ СИЛИ
			</Text>
			<Image
				src="https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
				display="block"
				width="60%"
				lg-order="-1"
				sm-width="100%"
				max-height="800px"
				srcSet="https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1671020611429-00076a47c1ca?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
				sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 1240px) 100vw,100vw"
			/>
			<Text
				color="--darkL2"
				margin="0px 0px 0px 0px"
				font="--lead"
				width="20%"
				align-self="flex-end"
				lg-width="100%"
				lg-max-width="540px"
				lg-align-self="auto"
			>
				Відпочивайте, надихайтесь та заряджайтесь енергією!
			</Text>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});